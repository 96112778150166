<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white grid grid-cols-12 " :style="`height:` +  mainHeight + `px; !important`">
        <div class=" mx-2 shadow-md relative" :class="isFilterOpen ? 'box col-span-10' : 'box col-span-12'">
          <div class="bg-white cust_card p-2 heading-3 text-text1 justify-between flex">
            <p>COGS by Product (SAGE)</p>
            <div class="top-0 absolute" :class="isFilterOpen ? '-right-4' : 'transition-all delay-75 -right-2'" >
              <button id="toggle" class="bg-gray-600 hover:bg-gray-700 transition delay-75 w-11 h-11 rounded-l-full " @click="openCloseFilter()">
                <span v-if="isFilterOpen"><i class="fas fa-caret-right h-6 w-6 text-white mt-1"></i></span>
                <p v-if="!isFilterOpen"><i class="fas fa-caret-left h-6 w-6 text-white mt-1"></i></p>
              </button>
            </div>
          </div>
          <div style="overflow-y: auto; white-space: nowrap;" class="table_containder" v-if="listingArray.length > 0 && firstLoading">
            <div :style="`max-height:` +  cardHight + `px; !important`" class="pt-2">
              <div id="chart" class="graphic-containers">
                <apexchart :height="chartHeight" type="bar" :options="chartOptions" :series="series1"></apexchart>
              </div>
            </div>
          </div>
          <div class="layout m-2" v-if="listingArray.length === 0 && firstLoading">
            <div class="flex p-3 text-sm text-primary2 bg-white border-blue-100 border-2 rounded-lg  items-center" role="alert">
              <svg aria-hidden="true" class="flex-shrink-0 inline w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">Info</span>
              <div>
                <p class=" font-medium heading-4">No record found.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="box col-span-2 overflow-y-auto mx-2 shadow-md transition-transform delay-300 ease-in-out transition-2" :style="!isFilterOpen ? 'display: none' : ''">
          <GlobalFilter :callFrom="'Sage'" :callParent="'Cost of goods sold'"  @closeFilter="closeFilter" :isFilterOpen="isFilterOpen"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import {downloadFile} from '@/utils/common.js'
import moment from 'moment'
import GlobalFilter from '@/View/Admin/Components/filterWithoutSelection.vue'
import API from '@/api/App.js'
// import Pagination from '@/View/components/pagination.vue'
export default {
  components: {
    GlobalFilter
  },
  data () {
    return {
      chartHeight: '2000',
      series1: [],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 'auto',
          stacked: true,
        },
        plotOptions: {
          bar: {
            columnWidth: '',
            horizontal: true,
            dataLabels: {
              total: {
                enabled: false,
                offsetX: 0,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              }
            }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          title: {
            text: "Value",
            style: {
              color: '#008FFB',
            }
          },
          categories: [],
          labels: {
            formatter: function (val) {
              return val + ""
            }
          }
        },
        yaxis: {
          title: {
            text: "Item Code",
            style: {
              color: '#008FFB',
            }
          },
        },
        tooltip: {
          custom: function({seriesIndex, dataPointIndex, w}) {
            // console.log('series', series)
            // console.log('seriesIndex', seriesIndex)
            // console.log('dataPointIndex', dataPointIndex)
            // console.log('w', w)
            return '<ul style="padding:4px;">' +
            '<li style="display:flex"><b>Order Source</b>: <p style="width:200px !important;overflow-wrap: anywhere;white-space: pre-wrap;">' + w.config.xaxis.categories[dataPointIndex] + '</p></li>' +
            '<li><b>' + w.config.series[seriesIndex].name + '</b>: $' + w.config.series[seriesIndex].data[dataPointIndex].toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '</li>' +
            '</ul>';
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      isFilterOpen: true,
      searchLoader: false,
      listingArray: [],
      filterObject: {
        startDate: '',
        endDate: '',
        minDate: '',
        maxDate: '',
      },
      cardHight: 0,
      mainHeight: 0,
      defaultStartDate: '',
      defaultEndDate: '',
      firstLoading: false,
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    this.cardHight = window.innerHeight - 171
    this.mainHeight = window.innerHeight - 161
    console.log('this.cardHight', this.cardHight, this.mainHeight)
    this.defaultEndDate = new Date()
    let currentDate = new Date()
    this.defaultStartDate = new Date(currentDate.setDate(currentDate.getDate() - 29))
    this.filterObject.startDate = this.defaultStartDate
    this.filterObject.endDate = this.defaultEndDate
  },
  watch: {
  },
  methods: {
    closeFilter (data) {
      console.log('data', data)
      this.filterObject.startDate = data.startDate
      this.filterObject.endDate = data.endDate
      this.getListing()
    },
    openCloseFilter () {
      if (this.isFilterOpen) {
        this.isFilterOpen = false
      } else {
        this.isFilterOpen = true
      }
      console.log('openCloseFilter')
    },
    getListing () {
      this.chartOptions.xaxis.categories = []
      this.series1 = []
      this.firstLoading = false
      console.log('this.filterObject.startDate 123 123', this.filterObject.startDate)
      console.log('this.filterObject.endDate 123 123', this.filterObject.endDate)
      let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
      let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
      this.$store.dispatch('SetLoader', {status: true, message: 'Please wait, it takes some time ...'})
      this.searchLoader = true
      API.SageCogsListing(
        utcSTART,
        utcEND,
        false,
        response => {
          this.searchLoader = false
          this.firstLoading = true
          this.listingArray = response.Data === null ? [] : response.Data
          console.log(response.Data)
          if (this.listingArray.length === 1) {
            this.chartOptions.plotOptions.bar.columnWidth = '20%'
          } else {
            this.chartOptions.plotOptions.bar.columnWidth = '70%'
          }
          this.addTitle()
          for (let index = 0; index < this.listingArray.length; index++) {
            this.chartOptions.xaxis.categories.push(this.listingArray[index].itemCode)
            this.series1[0].data.push(this.listingArray[index].costOfGoodsSold)
            this.series1[1].data.push(this.listingArray[index].profitMargin)
            this.series1[2].data.push(this.listingArray[index].revenue)
          }
          if (this.chartOptions.xaxis.categories.length > 0 && this.chartOptions.xaxis.categories.length < 200) {
            this.chartHeight = '3000'
          } else if (this.chartOptions.xaxis.categories.length > 200 && this.chartOptions.xaxis.categories.length < 300) {
            this.chartHeight = '5000'
          } else if (this.chartOptions.xaxis.categories.length > 300 && this.chartOptions.xaxis.categories.length < 400) {
            this.chartHeight = '7000'
          } else if (this.chartOptions.xaxis.categories.length > 400 && this.chartOptions.xaxis.categories.length < 500) {
            this.chartHeight = '9000'
          } else if (this.chartOptions.xaxis.categories.length > 500 && this.chartOptions.xaxis.categories.length < 600) {
            this.chartHeight = '10000'
          } else if (this.chartOptions.xaxis.categories.length > 600 && this.chartOptions.xaxis.categories.length < 700) {
            this.chartHeight = '12000'
          } else if (this.chartOptions.xaxis.categories.length > 700 && this.chartOptions.xaxis.categories.length < 800) {
            this.chartHeight = '14000'
          } else if (this.chartOptions.xaxis.categories.length > 800 && this.chartOptions.xaxis.categories.length < 900) {
            this.chartHeight = '16000'
          } else if (this.chartOptions.xaxis.categories.length > 900 && this.chartOptions.xaxis.categories.length < 1000) {
            this.chartHeight = '18000'
          } else if (this.chartOptions.xaxis.categories.length > 1000) {
            this.chartHeight = '20000'
          }
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
        },
        error => {
          this.resetPaginationValues = false
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading ...'})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addTitle () {
      this.series1 = []
       let chartTitle = ['Cost of Goods Sold', 'Profite Margin', 'Revenue']
       for (let index = 0; index < chartTitle.length; index++) {
         this.series1.push({
           name: chartTitle[index],
           data: []
         })
       }
    },
    sortByColumn (i, val1, val2) {
      this.titleObj[i].sort = !val1
      this.pagination.descending = val1
      this.pagination.sortBy = val2
      if (this.pagination.descending) {
        this.listingArray.sort(function (a, b) {
          if (val2 === 'itemCode') {
            var nameA = a.itemCode
            var nameB = b.itemCode
          } else if (val2 === 'costOfGoodsSold') {
            nameA = a.costOfGoodsSold
            nameB = b.costOfGoodsSold
          } else if (val2 === 'profitMargin') {
            nameA = a.profitMargin
            nameB = b.profitMargin
          } else if (val2 === 'revenue') {
            nameA = a.revenue
            nameB = b.revenue
          } else if (val2 === 'transactionQty') {
            nameA = a.transactionQty
            nameB = b.transactionQty
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      } else {
        console.log('click 2')
        this.listingArray.reverse(function (a, b) {
          if (val2 === 'itemCode') {
            var nameA = a.itemCode
            var nameB = b.itemCode
          } else if (val2 === 'costOfGoodsSold') {
            nameA = a.costOfGoodsSold
            nameB = b.costOfGoodsSold
          } else if (val2 === 'profitMargin') {
            nameA = a.profitMargin
            nameB = b.profitMargin
          } else if (val2 === 'revenue') {
            nameA = a.revenue
            nameB = b.revenue
          } else if (val2 === 'transactionQty') {
            nameA = a.transactionQty
            nameB = b.transactionQty
          }
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        })
      }
      this.dragArray('false')
      this.titleObj[i].isDesc = !this.titleObj[i].isDesc
    },
    clearSearch () {
      this.itemSearchVal = ''
      this.reqStateList = this.StateList
    },
    clearSearch2 () {
      this.itemNameSearchVal = ''
      this.reqPaymentList = this.PaymentList
    },
    async exportReport () {
      if (this.firstLoading && this.listingArray.length > 0) {
        let utcSTART = moment(this.filterObject.startDate).format("YYYY-MM-DD");
        let utcEND = moment(this.filterObject.endDate).format("YYYY-MM-DD");
        this.searchLoader = true
        if (this.filterObject.itemSKUList.length === 0) {
          this.filterObject.itemSKUList  = []
        }
        if (this.filterObject.itemNameList.length === 0) {
          this.filterObject.itemNameList = []
        }
        let day1 = moment(this.filterObject.startDate).format("MM-DD-YY")
        let day2 = moment(this.filterObject.endDate).format("MM-DD-YY")
        let Time = 'Cogs SAGE Product-' + day1 + '-To-' + day2
        let data = new FormData()
        data.append('from', utcSTART)
        data.append('to', utcEND)
        data.append('export', true)
        await this.downloadAttachment('api/v1/analytics/sage/cogs/product', `${Time}` + '.xlsx', data)
      }
    },
    amountFormaterWithToFix (amount) {
      let value = parseFloat(amount).toFixed(2)
      // return '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return `$${value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
    },
    async downloadAttachment (subUrl, fileName, formData) {
      let url = this.$store.getters.liveServerUrl + subUrl
      // If Want To cancel request
      const cancelToken = axios.CancelToken
      const source = cancelToken.source()
      let originalFileName = fileName
      this.$store.dispatch('CancelDownloadRequest', source)
      // Show loader while downloading
      this.$store.dispatch('SetDownloadLoader', true)
      // Calling Api and waiting for response
      let response = await downloadFile(formData, url, source)
      // Checking for status if 200 == success
      if (response.status === 200) {
        this.$store.dispatch('CancelDownloadRequest', null)
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([response.data]))
        console.log(window.URL.createObjectURL(new Blob([response.data])))
        link.setAttribute('download', originalFileName)
        document.body.appendChild(link)
        link.click()
        // CLosing loader and reseting text in loader
        this.$store.dispatch('SetDownloadLoader', false)
        this.$store.dispatch('SetDownloadMessage', '')
      } else {
        this.$store.dispatch('CancelDownloadRequest', null)
        this.$store.dispatch('SetDownloadMessage', '')
        this.$store.dispatch('SetDownloadLoader', false)
      }
    },
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
.rightText {
  text-align: right !important;
  justify-content: right !important;
}
.centerText {
  text-align: center !important;
  justify-content: center !important;
}
.small_col2 {
  /* min-width: 110px !important; */
  /* width: 110px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.smallx_col {
  /* min-width: 250px !important; */
  /* width: 250px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.small_col {
  /* min-width: 160px !important; */
  /* width: 167px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.overFlowParaA {
  word-break: break-all !important;
  white-space: initial !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block;
}

.progress-bar {
  height: 2px;
  background-color: #0a7ef9;
  width: 100%;
  overflow: hidden;
}

.button_position {
  position: absolute;
  z-index: 10!important;
  height: 0px!important;
  /* position: fixed; */
}
.xsmall_col {
  /* min-width: 54px !important; */
  /* width: 54px !important; */
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #74b4f9;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
.graphic-containers {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
