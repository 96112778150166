<template>
  <div>
    <div class="h-full">
      <div class="rounded bg-white">
        <div>
          <SageChart></SageChart>
        </div>
        <div class="mt-8">
          <SageText></SageText>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import SageChart from './component/sageChart.vue'
import SageText from './component/sagetext.vue'
export default {
  components: {
    SageChart,
    SageText,
  },
  data () {
    return {
    }
  },
  created() {
  },
  beforeMount () {
  },
  mounted () {
    document.title = 'COGS ◾ SAGE'
  },
  watch: {
  },
  methods: {
  },
  beforeDestroy () {
  }
}
</script>
<style scoped>
</style>
